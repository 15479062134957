







































import { Vue, Component } from "vue-property-decorator";
import serve from "@/serve/order";
import Nav from "@/components/header.vue";
import { Toast } from "vant";
@Component({
  components: {
    Nav,
  },
})
export default class Refund extends Vue {
  show = false;
  reason = "";
  describes = "";
  columns = ["工艺不符", "工艺误差", "协商一致退换", "其他原因"];
  private onCancel(): void {
    this.show = false;
  }
  private onConfirm(val: string): void {
    this.reason = val;
    this.show = false;
  }
  async onSubmit() {
    const data = {
      describes: this.describes,
      orderId: this.$route.query.id,
      pictures: [{}],
      reason: this.reason,
      type: 0,
    };
    const res = await serve.refund(data);
    if (res.data.code === 0) {
      Toast.success("提交成功,请等待商家回复");
      this.$router.push("/order");
    } else {
      Toast.fail(res.data.message);
    }
  }
}
